import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import Logotest from '../img/clases/Logotest512.png'

function AppNavbar() {
  const [expanded, setExpanded] = useState(false);

  function handleMenuClose() {
    setExpanded(false);
  }

  return (
    <>
      <Navbar className="barra-menu" expand="lg" expanded={expanded}>
        <Container>
        <Navbar.Brand as={Link} to="/">
            <img
              src={Logotest}
              alt="Taller de Ceramica Gres"
              height="50"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                className="text-light"
                as={Link}
                to="/"
                onClick={handleMenuClose}
              >
                Clases de Cerámica Gres
              </Nav.Link>
              <Nav.Link
                className="text-light"
                as={Link}
                to="/ceramistachilena"
                onClick={handleMenuClose}
              >
                Quién Soy
              </Nav.Link>
              <Nav.Link
                className="text-light"
                as={Link}
                to="/galeria"
                onClick={handleMenuClose}
              >
                Galería
              </Nav.Link>
              <Nav.Link
                className="text-light"
                as={Link}
                to="/contacto"
                onClick={handleMenuClose}
              >
                Contacto
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-0">
        <Outlet />
      </Container>

    </>
  );
}

export default AppNavbar;
