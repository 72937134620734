import React from 'react'


const ContenidoClases = () => {
  return (
    <div>
        <h2>Contenido de las clases</h2>
        <ul className="list-unstyled">
            <li><br />Preparación de la pasta para iniciar el modelado. Amasar, homogeneizar y compactar.</li>
            <li><br />Técnicas de modelado en forma manual.</li>
            <li><br />Construcción por pellizco, rollo o colombín, placas y bulto.</li>
            <li><br />Técnicas decorativas. Aplicación de óxidos, engobes y esmaltes. </li>
        </ul>
    </div>
  )
}

export default ContenidoClases