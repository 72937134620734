import React from 'react'
import { Card, Container } from 'react-bootstrap'
import BotonEnviarWhatsApp from './Whatsapp'

const Inscripciones = () => {
  return (
    <Container>
          
        <Card style={{backgroundColor: "#D9D7D770", backdropFilter: "blur(5px)"}}>
        <h2 className="mt-2">¿Cómo inscribirse?</h2>
        <p className="p-2">Llama o envía un whatsapp al +56992215618. Ahí te puedes comunicar con Susana quien te indicará si hay cupos disponibles para el grupo que te interesa y cuándo puedes empezar.</p>
        <p>El pago se realiza mediante transferencia electrónica al comienzo de tus clases.</p>
        <BotonEnviarWhatsApp />
        </Card>
    </Container>
  )
}

export default Inscripciones