import React from 'react'


const ObjetivoClases = () => {
  
    return (
        <div>
            <h2>Objetivo</h2>
                <ul className="list-unstyled">
                    <li><br />Aprender las técnicas del manejo, modelado y construcción en arcilla, entendiendo el material, sus posibilidades y limitaciones. </li>
                    <li><br />Desarrollar la creación y la expresión personal a través de las técnicas de la cerámica, desarrollando el juicio estético personal.</li>
                    <li><br />El alumno deberá enfrentar los problemas constructivos de las diferentes técnicas, apoyado en la base teórica transmitida por la profesora.</li>
                </ul>
        </div>
      )
}

export default ObjetivoClases