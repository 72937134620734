import React from 'react'
import { Card, Container, Image } from 'react-bootstrap'
import AppFooter from '../components/AppFooter'
import BotonEnviarWhatsApp from '../components/Whatsapp'
import BotonTelefono from '../components/BotonTelefono'
import { Telephone, Instagram, QuestionCircle } from 'react-bootstrap-icons';
import RedesSociales from '../components/RedesSociales'
import FAQ from '../components/FAQ'
import TituloContacto from '../img/clases/TituloContacto.png'

const Contacto = () => {
  return (
    <div>
      <section className="my-section">
      <div className="container-fluid">
      <Image src={TituloContacto} className="img-fluid" />
      </div>
    </section>

      <div className="d-flex flex-wrap justify-content-center">
        <Card style={{ width: "25rem", margin: "1rem", backgroundColor: "#D9D7D770", backdropFilter: "blur(5px)"}}>
          <div className="text-center mt-3">
            <Telephone size={50} />
          </div>

          <Card.Body>
            <Card.Title></Card.Title>
            <Card.Text>
              <p>Me puedes llamar por teléfono al +56 9 9221 5618</p>
              <BotonTelefono />

              <p>
                <br />O me puedes enviar un mensaje de whatsapp
              </p>

              <BotonEnviarWhatsApp />

              <p>
                Asegúrate de haber leído las preguntas frecuentes. Hay respuesta
                para las preguntas más habituales :)
              </p>
            </Card.Text>
            {/* <Button variant="primary">Inscríbete</Button> */}
          </Card.Body>
        </Card>

        <Card
          className="card-quiensoy"
          style={{ width: "25rem", margin: "1rem", backgroundColor: "#D9D7D770", backdropFilter: "blur(5px)" }}
        >
          <div className="text-center mt-3">
            <Instagram size={50} />
          </div>
          <Card.Body>
            <Card.Title>Redes Sociales</Card.Title>
            <RedesSociales />
            {/* <Button variant="primary">Inscríbete</Button> */}
          </Card.Body>
        </Card>

        <Container className="d-flex justify-content-center">
          <div className="col-md-8 col-lg-6">
            <Card className="mx-auto" style={{ margin: "1rem",  backgroundColor: "#D9D7D770", backdropFilter: "blur(5px)"}}>
              <div className="text-center mt-3">
                <QuestionCircle size={50} />
              </div>
              <Card.Body>
                <Card.Title>Preguntas Frecuentes</Card.Title>
                <Card.Text>
                  <FAQ />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
      <AppFooter />
    </div>
  );
};

export default Contacto