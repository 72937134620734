import React from 'react';
import { Button } from 'react-bootstrap';

const BotonTelefono = () => {
  return (
    <Button variant="primary" href="tel:+56992215618">
      Llamar por teléfono
    </Button>
  );
};

export default BotonTelefono;
