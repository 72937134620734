import React from 'react';
import { Card } from 'react-bootstrap';
import { Instagram, Facebook, Twitter } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const RedesSociales = () => {
  return (
    <Card style={{backgroundColor: "#D9D7D770", backdropFilter: "blur(5px)"}}>
      <Card.Body>
        {/* <Card.Title>Aquí nos encuentras</Card.Title> */}
        <Card.Text>
          <Link to="https://www.instagram.com/ceramicagres/" target="_blank">
            <Instagram className="me-3" size={32} color="#E1306C" />
          </Link>
          <Link to="https://www.facebook.com/tallerlosjacintos" target="_blank">
            <Facebook className="me-3" size={32} color="#3B5998" />
          </Link>
          <Link to="https://twitter.com/ceramicagres" target="_blank">
            <Twitter className="me-3" size={32} color="#1DA1F2" />
          </Link>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default RedesSociales;