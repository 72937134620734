import React from 'react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  // Original: 600px de Ancho
  // Miniatura: 150 pixeles de ancho
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiPwfjTl3_Ut0ADXjzFV4oTzaivLdr6NuqSjpwsHke0-c1XhWnGRBfzq30Q_oWfYbIOQkjApiYNdjq-EIqkKsnYWvAADR1qEJ7AY89jFSOW3zaAdKYDddDFTeVk55-bnVVS52FGVIsXNSPU43JmH4zZ5DXVFVIXVXKXUgwlcSngNcAtTzyJzMh9Ioga/s16000/Galeria5.webp',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiyBxvzS40S2vldiRD6TSGsIPsA0SQn5LgqAUU6sTG4RyPYPEQXuidT_m2PSO-a2msTSgh4eWXxyBZpozAV0LEB714ETcp1Z4EPUGETH2GIPWF82NFnbwEhxXCdiPYd1-7cP_0T1a4eXLG56pwvh0zkPC3D0LJQzMlPzepJPaPishp6RY5p8c1OJi1H/s1600/Galeria1Mini5.webp',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg4cPb__3clcfxEmTl9CmIU3vnh1uTTFjqJZQB1K9QiQzzPLQJCtYaJMiDEHaunrIXaMV8Mqak9YCeLOKxBg_-IY42BsUojE8aTH7iR3fx8AY9wMThqjgnNjU_skmwxLhINWRjJG1D4UEdJ7gWFZjQqaQUf-yBU3g81jg-EDqTTxLjjJ4YxZUUchoY9/s16000/Galeria4.webp',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjPxODMVRQ7e9X7Zt26GSjq0HOfw10V-k-6yZLlfHzKp1aAheP9fPNXI7z2Nhg4T3gul4A263Wohm8wgulaq8cO0QwxVcAUp1qCL1iI2xdErbXxvmD93koMU8pa-9IuxOxOTeJma7gpZczz2WXyWApLQIJOjVMkYwGjaaIMmxEOPCFfs56ZC8jT5R3x/s1600/Galeria1Mini4.webp',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiGfAx2c5wc14aZTIVTB0es5EL2RGpXpAN1A1Kuoogw7tHqPTyYIylrsRcl1sTBZCgnYioJEmrEXfzQZwg8igsEpylYCCTDOkuhAr7WaqFjS1jW5PIiYF9XOxi0Xxfo4dfT702Nx3J2o9RMP0La6GDjJTpvJHkj8MNP7vPWBKmzusOii4xRksWoNwE8/s16000/Galeria3.webp',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiJquE0PDbQetaPuSlgxmOxEhOvOnJQ1ZObjLZTRPlf7PRXsMvYQImC18_cBgKBTIGnzKue9HM1V814ItIIPWj8b1QqDYSuYW9X4Zf_YtGHm39bZMf5r_0SFxXdLJ68myFLI65AYg5gQfuYP_hsinWPWG3Q4ibp0jJnAjqcv0ck9ojW9p1blqppwwfm/s1600/Galeria1Mini3.webp',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhO35-v46G0fqsYM1tyMsvd31JWTJvRNeBEVBWDOBU2XE9P-gZnumSMjq71jcpt4-MkRkj81s9MpzoUg0ixcRZjhIjLxhPacD1-aYtFA76avm6-TRMZjTdlOdEHsh3b4GVmVB89NJ6OgiELpKQC8gdA1oEmDOqeKVOhCuDST124PfkJXgIlAzq1gIbZ/s16000/Galeria2.webp',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj2mMdH9xVa4QnWP36DhFQpCZw1DnHCahfnmRPDr21jT1p5-wU9AbLsuQOnxtF7aNAgECzn3tzQIJDRFccBS_1lOB1f1CDWs97YVB8FUiN5JIUPQaxKWHp7cU_999733k5MqH-Y19uRKPWFZBBLKSpUjZ1r3uumSLBvYHfPBUI5t_24nDbT7OyrO5-x/s150/galeria1mini2.webp',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj20qwc4QBRhHioVA5DYYcv-dPf_OXrdQHbPpQUgvU6UiwCe9GL1KD_ArOkx3b8qc7goRJ4N6Zm90uCuGn93J03vZJQyukOQ1QLGg2-X-yHZiETUbWdwV195UBrbtXpmX1htKwrzc2H9s08PphYnmTibF1P9gD9Hv1xrgrCBixTN6sLk6B4PIFYzpGT/s16000/Galeria1.webp',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiRuO3rr3EetURuF3tcw7bXU4fws5WrvU9SNWeC6Fa4JHbEJvUJANM1q5fwga921uG0-LQKvDvDxpIdH76q2aL-lziefI0bSqi5fVG1vvo6MzC8RubDfs3SksT1b5XCx7ZYBz6oyrGWFiiskoCzvWtOYIAaGT749bJeFggG37-CJjxIAbff82NkxGrO/s1600/Galeria1Mini1.webp',
  },
];

class Galeria2 extends React.Component {
  render() {
    return (
      <ImageGallery
        items={images}
        thumbnailPosition="left"
      />
    );
  }
}

export default Galeria2;