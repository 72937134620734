import React from 'react';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function enviarMensaje() {
  const numeroTelefono = '56992215618'; // Reemplaza con el número de teléfono al que se enviará el mensaje
  const mensaje = 'Hola, vengo de la página web y quiero inscribirme en las clases de cerámica gres.'; // Reemplaza con el mensaje que se enviará
  const url = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
  window.open(url);
}

function BotonEnviarWhatsApp() {
  return (
    <Container className="mb-2 mt-2">
      <Button className="mb-4" variant="success" onClick={enviarMensaje}>
        <FontAwesomeIcon icon={faWhatsapp} /> Enviar mensaje de WhatsApp
      </Button>
    </Container>
  );
}

export default BotonEnviarWhatsApp;
