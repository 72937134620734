import { Route, Navigate, Routes } from 'react-router-dom';
import ClasesDeCeramica from '../Pages/ClasesDeCeramica';
import QuienSoy from '../Pages/CeramistaChilena';
import Galeria from './Galerias/Galeria';
import Contacto from '../Pages/Contacto';

function AppRoutes() {
  return (
    <>
    <Routes>
      <Route exact path="/" element={<Navigate to="/clasesdeceramica" />} />
      <Route path="/clasesdeceramica" element={<ClasesDeCeramica />} />
      <Route path="/ceramistachilena" element={<QuienSoy />} />
      <Route path="/galeria" element={<Galeria />} />
      <Route path="/contacto" element={<Contacto />} />

      </Routes>
    </>
  );
}

export default AppRoutes;
