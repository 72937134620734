import React from 'react'
import { Card, Container, Image } from 'react-bootstrap'
import Inscripciones from '../Inscripciones';

import AppFooter from "../AppFooter";
import Galeria1 from "./Galeria1";
import Galeria2 from "./Galeria2";
import Galeria3 from "./Galeria3";
import TituloGaleria from "./imgGaleria/TituloGaleria.png";

function Galeria() {
  return (
    <>
      <section className="my-section">
        <div className="container-fluid">
          <Image src={TituloGaleria} className="img-fluid" />
        </div>
      </section>
      <h1 className="d-flex flex-wrap justify-content-center mt-4 mb-4">
        Piezas en Cerámica Gres
      </h1>
      <div className="bg-light" style={{ margin: "10px", borderRadius: "10px"}}>
      

      <Container className="p-2">
        <Galeria1 />
      </Container>
      </div>
     
      <h1 className="d-flex flex-wrap justify-content-center mt-5 mb-4">
        Clases en el taller
      </h1>
      <div className="bg-light" style={{ margin: "10px", borderRadius: "10px" }}>
      

      <Container className="p-2">
        <Galeria2 />
      </Container>
      </div>


      {/* <h1 className="d-flex flex-wrap justify-content-center mt-5 mb-4">
        Galería 3
      </h1>
      <div className="bg-light" style={{ margin: "10px", borderRadius: "10px" }}>
      

      <Container className="mb-4 p-2">
        <Galeria3 />
      </Container>
      </div> */}

      <Container fluid className="mt-5 mb-2">
        <Inscripciones />
      </Container>
      

      <AppFooter className="fixed-bottom" />
    </>
  );
}

export default Galeria;
