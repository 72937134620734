import React from 'react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [

  // Original: 600px de Ancho
  // Miniatura: 150 pixeles de ALTO 
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi_ptfehMIy6ihN4dCkpbSTJwoj7xFW_9kXG2pzvUXLH149mRPDGK3MUbqE-94xhFwgsPJC8wJtUSmxuj_gJ5z9A3JrmjFUWGqJOQzR-4DppJMEb0iOSRArLxQKdRCveu5GtUo9eEICbK08_UFpW7JZJiVU3Up1WU01rwcLmIsNMvrAUwOH5Z3_2TCp/s16000/Galeria1-obj-1.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjxIymvKMX3q8z4cfGR6gBuNxp2Ltxksr-Du58AqTQ0VDWFpZej9EMlWvNIckmg7hyws5gZLz8t_TOxIYTeX3doqnGePLkqki3CR53jIN9fpszJo9WDHq8G65lU6EJjlqfy1gm0zffsAT4kYbEZhrsl_jABI36KPEsCLJtrOjkFbVP-_NlAgJHcDkjL/s1600/Galeria1-Mini-obj-1.jpg',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh3x8B2KRRqkXujB_P-9YF3kcXQxj8PpblJiY8mSkgfBnWjJdS-zp5xmbkx9b_C8nv3w3BdzpAwXvukUDIKgC-pxm_ApNPevbzBf_gG-G6eBpU0mgW4vy7tN2XnFoRP9HrYPD-Knw2nXAMXkzGVyXqSjOJ8SztSrg693IB7bL8q-bf76gDW8es1GGJZ/s16000/Galeria1-obj-2.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh13HPrG7ZglC0gxsH83wiJfQmxPqeWMKfZu4dXv_xG6gm3jtNeeXo--bq9AHSpIf4B60Vsg3zP9J-d16wqgaCsT3fT_tBD5NYR6m6bci6jxHAhLF219vVVeAbtIaK1_upJRvU12EyrfixMlDLATVvrtgQkaXwOmDZHTYDYuXQ5VZt-ObMkq14quzK0/s1600/Galeria1-Mini-obj-2.jpg',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg4DXpHYgKFL7YVzA9seSWZ_k5wOmhkQnYNUdTzByp4zL9oRexnShVSkIcNzvH58lKwE3ZHelBorEqH6Wr8ome41Uh475hgZz_6iUOzgYufZCUEmXQaq4_m_N3ukhq3fHkbUeWIJEavs7iTy-758NpKCs3f2XD4jDN7Lipl_TsCOyJaHYh6P31H9JvQ/s16000/Galeria1-obj-3.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEga4NhxU7T9z1pQCHG9-z7wNxzVdBN_3y57TOZSkK8EgYPBNgb-8nmXIb65YOy-AmLY544OPIQm41MH2BzEqLYjn--QZih_vAawDFcdvysyqGq88OQZh8N9qdquJl7SZsZVKnVpL3D9f9kRp7iK6QHG43Oen5JVh5l_xZnpuMqv9W0xYFSjE_9vQyrX/s1600/Galeria1-Mini-obj-3.jpg',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiPYTEp-WWajAZ12LsU9oSjyc0nsfew-Po49vYqsGjC5_nzr4ck4B8LRHI7bcyhadE99x5ZhCkTjjS-mgzAOkAQnkguwP2siTlXNKx_0NYH9ZNGpB6Y9Su_i1ljBDXQCbs4AK1D3rO0wunyHSTY3PiH_LR6Yiig6BUsrm9WN4svqrzzHATyP3rTfcGm/s16000/Galeria1-obj-4.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhCsKdcp2DnAIkHmjR5X764Rh3WMon9Krc9TJfNhurq-K__2S8ylIgqpzLzAu0ECOveG8ojzDQt4AFsRcNh23cVV-RsH8vWgjKyp55FXAVL5jkIjqHhfOka6hsMcdF005HwXyYDbVa7imiZo5ISR2YRJId90yPErh_e6tVsHnRPA79L26paqHaK3aCD/s1600/Galeria1-Mini-obj-4.jpg',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAQBXhzV4bSDGmwtvzWZi9qDRRGO15_50Ki4UjPFx5DD5Jp_f1mh3BTCkTKMhzSm5S43sKgm68k2WRZsIULnPzOtBOtEVNRruEmLzsSC294rRjCicSJ5zA6QkgtTNDiAICMA2kYUMJVZmRn3jOuydDmEvi98fScdE1HZP0kI7jHRPDheRJZ8ZDLvy2/s16000/Galeria1-obj-5.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjvWW-1uyLYFBjpbdSdoniakf-siEFBsaZg7xl4dZSv8CBSulbn38yKUIWts50g0_16EI69nwVp2XUhXlF5gZ3lKiqpHRREFGWYWdVzjfSVE6vbw6HXCe1GWwy1jjuGyDaGc3GE43mxbjz6c9SQZG4GwsP15i7-KQ7-9l3CciwWnDoZLplde39kn4Si/s1600/Galeria1-Mini-obj-5.jpg',
  },
  {
    original: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEius9H71-XHMVnYAqA5Jlf7llmT5aNTWmYYg2xsiq5Loj7Uwy65FUuTFo2w4qNRyyWc7nzUHrx5JybTr63bdFDxW4gCRsIE_HMtjHP6Soe1fKXW2vRRUDhlMl1Hpmr1Exs_ZY5bH4bMNUDdiquS5nSO4dFqDg-EfNg0cDHPw7PzXZUvKGPoW5jEajIO/s16000/Galeria1-obj-6.jpg',
    thumbnail: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjw05tBo2PqDrIzQTmdV6SKRzfXsz7GXq8WKF9VDxHE12nLY0n3JVGDdvLWFlCVYmN_NqmhIQeiDvnlpHd1bR7pXWSVnDLLg9eLVW1vt6eKCTtINlnCqvW7Dw1b1XdjX684q9MBgfQmvRHPtGyVjxvj9H9SvsKU9PKUfY-xjuaMsNC3Qq0KBvg-XSuD/s1600/Galeria1-Mini-obj-6.jpg',
  },
];

class Galeria1 extends React.Component {
  render() {
    return (
      <ImageGallery
        items={images}
        thumbnailPosition="left"
      />
    );
  }
}

export default Galeria1;
