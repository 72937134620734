import React from "react";

function AppFooter() {
  return (
    <footer className="app-footer bg-light">
      <div className="bg-dark text-white p-3 text-center d-flex flex-column flex-md-row align-items-center justify-content-md-between">
        <p className="mb-0 text-muted" style={{ fontSize: "0.6rem" }}>Desarrollado por: illaDev | illafran.dev@gmail.com</p>
        <p className="mb-0 text-muted" style={{ fontSize: "0.6rem", lineHeight: "1" }}>Todos los derechos reservados &copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}

export default AppFooter;

