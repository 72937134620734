import React from 'react'
import { Card, Button, Image } from 'react-bootstrap';
// import { EmojiSmileFill, PersonFill, VectorPen } from 'react-bootstrap-icons';
import SusanaGrau from '../img/susanagrau/SusanaGrau.jpg';
import Horno from '../img/susanagrau/Horno.jpg'
import TrabajoTaller from '../img/susanagrau/TrabajoTaller.jpeg'
import BotonEnviarWhatsApp from '../components/Whatsapp';
import AppFooter from '../components/AppFooter';
import TituloSusana from '../img/clases/TituloSusana.png'

const CeramistaChilena = () => {
  return (
    <div>
      <section className="my-section">
      <div className="container-fluid">
      <Image src={TituloSusana} className="img-fluid" />
      </div>
    </section>

      <div className="d-flex flex-wrap justify-content-center">

      <Card style={{
        width: "25rem",
        margin: "1rem",
        backgroundColor: "#D9D7D770",
        backdropFilter: "blur(5px)"
      }}>
          
          <div className="text-center mt-3">
            <Image src={SusanaGrau} alt="Susana Grau - Ceramista chilena" className="img-fluid w-50" style={{ borderRadius: "5px" }}/>
            {/* <EmojiSmileFill size={96} /> */}
          </div>
          
          <Card.Body>
            <Card.Title></Card.Title>
            <Card.Text>
              <p>
                Soy Susana Grau, Educadora de Párvulos de la PUC de profesión y ceramista por opción.</p>
                
                <p>Desde los años 90 que estoy en la cerámica y desde 1994 impartiendo clases.</p>
                
                <p>La cerámica llegó a mi vida y la arcilla me cautivó. La magia de construir y levantar piezas con la plasticidad que entrega el material es infinita.
              </p>
            </Card.Text>
            {/* <Button variant="primary">Inscríbete</Button> */}
          </Card.Body>
        </Card>

        <Card style={{
        width: "25rem",
        margin: "1rem",
        backgroundColor: "#D9D7D770",
        backdropFilter: "blur(5px)"
      }}>
        <div className="text-center mt-3">
          <Image src={Horno} alt="Susana Grau - Horno" className="img-fluid w-50" style={{ borderRadius: "5px" }}/>
            {/* <PersonFill size={96} /> */}
          </div>
          <Card.Body>
            <Card.Title></Card.Title>
            <Card.Text>
              <p>
                Tan importante como la arcilla es el fuego, que termina el trabajo y siempre nos sorprende.
              </p>
              <p>
              La cerámica gres es una cerámica de alta temperatura (1200 a 1300 ºC). Es de color claro y tiene un bajo porcentaje de porosidad, lo que la hace prácticamente impermeable, porque la pasta se ha vitrificado tras la cocción. 
              </p>
            </Card.Text>
            {/* <Button variant="primary">Inscríbete</Button> */}
          </Card.Body>
        </Card>

        <Card style={{
        width: "25rem",
        margin: "1rem",
        backgroundColor: "#D9D7D770",
        backdropFilter: "blur(5px)"
      }}>
        <div className="text-center mt-3">
        <Image src={TrabajoTaller} alt="Susana Grau - Horno" className="img-fluid w-50" style={{ borderRadius: "5px" }}/>
            {/* <VectorPen size={96} /> */}
          </div>
          <Card.Body>
            <Card.Title>¿Qué enseño?</Card.Title>
            <Card.Text>
              <p>
                En el taller te enseño a levantar piezas de forma manual con diferentes técnicas:
              </p>
              <ul className="list-unstyled">
                <li>Pellizco</li>
                <li>Colombín o lulo</li>
                <li>Placa</li>
                <li>Bulto o ahuecado</li>
              </ul>
              <BotonEnviarWhatsApp />
            </Card.Text>
            {/* <Button variant="primary">Inscríbete</Button> */}
          </Card.Body>
        </Card>
        
      </div>
      <AppFooter />
    </div>
  );
}

export default CeramistaChilena