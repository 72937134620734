import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { CalendarWeek, Book, Check2Circle } from "react-bootstrap-icons";
import BotonEnviarWhatsApp from "../components/Whatsapp";
import FAQ from "../components/FAQ";
import ContenidoClases from "../components/ContenidoClases";
import ObjetivoClases from "../components/ObjetivoClases";
import Inscripciones from "../components/Inscripciones";
import alumnas2 from "../img/clases/alumnas2.webp";
import AppFooter from "../components/AppFooter";
import Titulo2 from '../img/clases/Titulo2.png'

const ClasesDeCeramica = () => {
  return (
    <>
      <div>

      <section className="my-section">
      <div className="container-fluid">
      {/* <MySlider /> */}
      {/* Insertar imagen */}
      <Image src={Titulo2} className="img-fluid" />
      </div>
    </section>


        
        {/* <h1 className="d-flex flex-wrap justify-content-center m-3 mb-4">
          Clases de Cerámica Gres
        </h1> */}
        {/* <Image src={titulo} alt="Susana Grau - Horno" className="img-fluid w-80" style={{ borderRadius: "5px" }}/> */}
        <div className="d-flex flex-wrap justify-content-center">
          <Row>
            <Col md={6} lg={3}>
              <Card
                className="mb-2"
                style={{
                  backgroundColor: "#D9D7D770",
                  backdropFilter: "blur(5px)",
                }}
              >
                <div className="text-center mt-3">
                  <Image
                    src={alumnas2}
                    alt="Susana Grau - Ceramista chilena"
                    className="foto-alumnas img-fluid"
                    style={{ borderRadius: "5px" }}
                  />
                  {/* <Person size={96} /> */}
                </div>
                <Card.Body>
                  <Card.Title>¿Cómo son las clases?</Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>
                        Son 4 clases al mes. Cada clase dura 2 horas y media
                      </li>
                      <li>
                        Clases personalizadas: Aptas para cualquier nivel de
                        conocimiento
                      </li>
                      <li>Puedes empezar en cualquier momento del año</li>
                    </ul>
                  </Card.Text>
                  {/* <Button variant="primary">Inscríbete</Button> */}
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={3}>
              <Card
                className="mb-2"
                style={{
                  backgroundColor: "#D9D7D770",
                  backdropFilter: "blur(5px)",
                }}
              >
                <div className="text-center mt-3">
                  <CalendarWeek size={96} />
                </div>

                <Card.Body>
                  <Card.Title>Días y horarios 2024</Card.Title>
                  <Card.Text>
                    <ul className="list-unstyled">
                      <li>
                        <p>
                          Grupo 1:
                          <br />
                          Lunes de 18:30 a 21:00
                        </p>
                      </li>
                      <li>
                        <p>
                          Grupo 2:
                          <br /> Miércoles de 10:00 a 12:30
                        </p>
                      </li>
                      <li>
                        <p>
                          Grupo 3:
                          <br /> Sábado de 10:00 a 12:30
                        </p>
                      </li>
                      <li>
                        <h4>
                          <br />
                          ¿Te quieres inscribir?
                        </h4>
                        {/* <Whatsapp size={20} /> */}
                        <BotonEnviarWhatsApp />
                      </li>
                    </ul>
                  </Card.Text>
                  {/* <Button variant="primary">Inscríbete</Button> */}
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={3}>
              <Card
                className="mb-2"
                style={{
                  backgroundColor: "#D9D7D770",
                  backdropFilter: "blur(5px)",
                }}
              >
                <div className="text-center mt-3">
                  <Book size={96} />
                </div>
                <Card.Body>
                  <ContenidoClases />
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={3}>
              <Card
                className="mb-2"
                style={{
                  backgroundColor: "#D9D7D770",
                  backdropFilter: "blur(5px)",
                }}
              >
                <div className="text-center mt-3">
                  <Check2Circle size={96} />
                </div>
                <Card.Body>
                  <ObjetivoClases />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Container className="mi-contenedor-faqs mt-4">
          <h1>Preguntas frecuentes</h1>
          <FAQ />
        </Container>

        <Col className="mt-2 mb-2">
          <Inscripciones />
        </Col>
      </div>

      <AppFooter />
    </>
  );
};

export default ClasesDeCeramica;
