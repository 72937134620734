import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQ = () => {
  return (
    
    <Accordion className="w-100">
      <Accordion.Item className="opaco" eventKey="0">
        <Accordion.Header>¿Cuánto cuesta?</Accordion.Header>
        <Accordion.Body>
          <p>
            El valor de la mensualidad es de $120.000.- Esto es, 4 clases al
            mes; cada clase con una duración de 2 horas y media.
          </p>
        </Accordion.Body>
      </Accordion.Item >
      <Accordion.Item className="opaco" eventKey="1">
        <Accordion.Header>¿Qué incluye el precio?</Accordion.Header>
        <Accordion.Body>
          <p>
            El precio incluye los materiales: arcilla, esmaltes, uso de
            herramientas, servicio de horneado para tus piezas.
          </p>
          <p>
            Y por supuesto, todas las piezas que realices te las llevas, son
            tuyas.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="2">
        <Accordion.Header>¿Qué necesito para empezar?</Accordion.Header>
        <Accordion.Body>
          <p>
            Para empezar solo necesitas traer tu delantal. No necesitas nada más. Te prestamos todo lo que necesitas
            para la clase.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="3">
        <Accordion.Header>¿Cuánto tiempo dura el taller?</Accordion.Header>
        <Accordion.Body>
          <p>
            El taller es continuo. Si no tienes ningún conocimiento, durante el
            primer mes aprenderás las técnicas básicas de manejo, modelado y
            construcción en arcilla y comenzarás a levantar tus propias piezas.
          </p>
          <p>
            Luego vas asistiendo al taller y podrás ver cómo, mes a mes, vas
            perfeccionando tu técnica, lo que se traducirá en piezas cada vez
            mejor elaboradas.
          </p>
          <p>
            Hay personas que se mantienen años asistiendo a clases, las
            posibilidades de construcción en arcilla son prácticamente
            infinitas.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="4">
        <Accordion.Header>
          ¿Qué pasa si no puedo asistir a una clase?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Si te pierdes una clase te damos la posibilidad de recuperarla en un día y horario que la profe definirá y que se irá avisando mes a mes.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="5">
        <Accordion.Header>¿Dónde está ubicado el taller?</Accordion.Header>
        <Accordion.Body>
          <p>
            Estamos ubicados en Providencia (Santiago de Chile), cerca del metro
            Bilbao. Puedes estacionar en la calle, no hay problema para
            encontrar lugar de estacionamiento.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="6">
        <Accordion.Header>¿Hacen clases de cerámica online?</Accordion.Header>
        <Accordion.Body>
          <p>
            No. Las clases de cerámica son prácticas en su totalidad. Se
            necesitan materiales, herramientas específicas y las piezas se
            hornean varias veces. Lamentablemente no es posible traspasar este
            tipo de práctica a formato online.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="7">
        <Accordion.Header>¿Hacen clases de torno?</Accordion.Header>
        <Accordion.Body>
          <p>
            No. En Taller Los Jacintos no ocupamos torno, todas las piezas se
            levantan de manera manual.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="8">
        <Accordion.Header>
          ¿Tienen servicio solamente de horno, para quemar mis piezas?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            No. No ofrecemos servicio de horno, solamente se hornean las piezas
            que los alumnos han construido en las clases.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="opaco" eventKey="9">
        <Accordion.Header>
          ¿Tienen catálogo y precios al por mayor? ¿Hacen regalos corporativos?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            No manejamos catálogo. Siempre hay stock de las piezas que hago,
            pero este tipo de trabajo es artesanal y se realiza de manera
            manual. Al ser todo hecho a mano, ninguna pieza es igual a la otra y
            no existe el concepto de "trabajo en serie". Si quieres muchas
            piezas debes saber:
          </p>
          <ul style={{ textAlign: "left" }}>
            <li>
              Vas a tener que esperar, no se pueden apurar los procesos de la
              cerámica. Aunque quisiera, no puedo realizar pedidos urgentes.
            </li>
            <li>
              No hay descuento por cantidad, a mayor cantidad mayor trabajo y
              gasto de materiales.
            </li>
            <li>
              Puedo hacer piezas muy parecidas, pero nunca habrá dos piezas
              exactamente iguales.
            </li>
            <li>
              Es caro. Si no estás dispuesto/a a pagar lo que vale el trabajo
              hecho a mano, por favor no pidas presupuestos.
            </li>
          </ul>
          <p>Se llama respeto por los artesanos y el trabajo hecho a mano.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    
  );
};

export default FAQ;
